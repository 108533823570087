import HomePage from './pages/Homepage'

function App() {
  return (
    <div className="App">
      <HomePage/>
    </div>
  );
}

export default App;
