import React from 'react';

const ContactUs = () => {
    const contactUsStyle = {
        display: 'flex',
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
        marginTop:'16px'
    };

    const mapStyle = {
        flex: 1,
        height: '400px',
    };

    const addressStyle = {
        flex: 1,
        padding: '20px',
    };

    const titleStyle = {
        fontWeight: 'bold',
        fontSize: '28px',
        marginBottom: '20px',
    };

    const underlineStyle = {
        content: '',
        display: 'block',
        width: '50px',
        height: '4px',
        backgroundColor: 'darkorange',
        margin: '10px auto',
    }

    return (
        <div id="contact" style={contactUsStyle}>

            <div style={mapStyle}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3779.4600793193827!2d79.53218817519576!3d18.68820928243817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a32d1d3526a88f9%3A0xfe37f1e9b023fb3!2sSINGARENI%20COLLERIES%20CBSE%20HIGH%20SCHOOL-8INCLINE%20COLONY(RG-2%20Area)%5BINTEGRATED%20STATE%26CBSE%20SYLLABUS)!5e0!3m2!1sen!2sin!4v1720953253798!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="School Location"
                ></iframe>
            </div>
            <div style={addressStyle}>
                <div style={titleStyle}>Contact Us</div>
                <div style={underlineStyle}></div>
                <p>Singareni Collieries High School Sector-III</p>
                <p>8 Incline, Godavarikhani</p>
                <p>Phone: +91 8332037511</p>
                <p>Email: schs@schssec3.in</p>
            </div>
        </div>
    );
}

export default ContactUs;
