import React from 'react';
import facultyImage1 from '../Assets/sample1.jpeg';
import facultyImage2 from '../Assets/sample1.jpeg';
import facultyImage3 from '../Assets/sample1.jpeg';

const facultyDetails = [
  {
    name: 'Dr. John Doe',
    qualifications: 'PhD in Mathematics',
    subjects: 'Mathematics, Physics',
    image: facultyImage1,
  },
  {
    name: 'Ms. Jane Smith',
    qualifications: 'MSc in Chemistry',
    subjects: 'Chemistry, Biology',
    image: facultyImage2,
  },
  {
    name: 'Mr. Robert Brown',
    qualifications: 'MA in English',
    subjects: 'English, History',
    image: facultyImage3,
  },
  {
    name: 'Dr. John Doe',
    qualifications: 'PhD in Mathematics',
    subjects: 'Mathematics, Physics',
    image: facultyImage1,
  },
  {
    name: 'Ms. Jane Smith',
    qualifications: 'MSc in Chemistry',
    subjects: 'Chemistry, Biology',
    image: facultyImage2,
  },
  {
    name: 'Mr. Robert Brown',
    qualifications: 'MA in English',
    subjects: 'English, History',
    image: facultyImage3,
  },
  {
    name: 'Dr. John Doe',
    qualifications: 'PhD in Mathematics',
    subjects: 'Mathematics, Physics',
    image: facultyImage1,
  },
  {
    name: 'Ms. Jane Smith',
    qualifications: 'MSc in Chemistry',
    subjects: 'Chemistry, Biology',
    image: facultyImage2,
  },
  {
    name: 'Mr. Robert Brown',
    qualifications: 'MA in English',
    subjects: 'English, History',
    image: facultyImage3,
  },
  {
    name: 'Dr. John Doe',
    qualifications: 'PhD in Mathematics',
    subjects: 'Mathematics, Physics',
    image: facultyImage1,
  },
  {
    name: 'Ms. Jane Smith',
    qualifications: 'MSc in Chemistry',
    subjects: 'Chemistry, Biology',
    image: facultyImage2,
  },
  {
    name: 'Mr. Robert Brown',
    qualifications: 'MA in English',
    subjects: 'English, History',
    image: facultyImage3,
  },
  // Add more faculty members here as needed
  {
    name: 'Dr. John Doe',
    qualifications: 'PhD in Mathematics',
    subjects: 'Mathematics, Physics',
    image: facultyImage1,
  },
  {
    name: 'Ms. Jane Smith',
    qualifications: 'MSc in Chemistry',
    subjects: 'Chemistry, Biology',
    image: facultyImage2,
  },
  {
    name: 'Mr. Robert Brown',
    qualifications: 'MA in English',
    subjects: 'English, History',
    image: facultyImage3,
  },
  {
    name: 'Dr. John Doe',
    qualifications: 'PhD in Mathematics',
    subjects: 'Mathematics, Physics',
    image: facultyImage1,
  },
  {
    name: 'Ms. Jane Smith',
    qualifications: 'MSc in Chemistry',
    subjects: 'Chemistry, Biology',
    image: facultyImage2,
  },
  {
    name: 'Mr. Robert Brown',
    qualifications: 'MA in English',
    subjects: 'English, History',
    image: facultyImage3,
  },
  {
    name: 'Dr. John Doe',
    qualifications: 'PhD in Mathematics',
    subjects: 'Mathematics, Physics',
    image: facultyImage1,
  },
  {
    name: 'Ms. Jane Smith',
    qualifications: 'MSc in Chemistry',
    subjects: 'Chemistry, Biology',
    image: facultyImage2,
  },
  {
    name: 'Mr. Robert Brown',
    qualifications: 'MA in English',
    subjects: 'English, History',
    image: facultyImage3,
  },
  {
    name: 'Dr. John Doe',
    qualifications: 'PhD in Mathematics',
    subjects: 'Mathematics, Physics',
    image: facultyImage1,
  },
  {
    name: 'Ms. Jane Smith',
    qualifications: 'MSc in Chemistry',
    subjects: 'Chemistry, Biology',
    image: facultyImage2,
  },
  {
    name: 'Mr. Robert Brown',
    qualifications: 'MA in English',
    subjects: 'English, History',
    image: facultyImage3,
  },
];

function Faculty() {
  const facultyStyle = {
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    marginTop: '20px',
  };

  const titleStyle = {
    fontWeight: 'bold',
    fontSize: '28px',
    marginBottom: '20px',
    position: 'relative',
  };

  const underlineStyle = {
    content: '',
    display: 'block',
    width: '50px',
    height: '4px',
    backgroundColor: 'darkorange',
    margin: '10px auto',
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '20px',
    justifyItems: 'center',
  };

  const facultyMemberStyle = {
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '250px',
  };

  const imageStyle = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    marginBottom: '10px',
  };

  return (
    <div id="faculty" style={facultyStyle}>
      <div style={titleStyle}>
        Faculty
        <div style={underlineStyle}></div>
      </div>
      <div style={gridStyle}>
        {facultyDetails.map((faculty, index) => (
          <div key={index} style={facultyMemberStyle}>
            <img src={faculty.image} alt={faculty.name} style={imageStyle} />
            <h3>{faculty.name}</h3>
            <p><strong>Qualifications:</strong> {faculty.qualifications}</p>
            <p><strong>Subjects:</strong> {faculty.subjects}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faculty;
