import React from 'react';
import Header from '../components/Header';
import Faculty from '../components/Faculty';
import AboutUs from '../components/AboutUs';
import Gallery from '../components/Gallery';
import ContactUs from '../components/ContactUs';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles
import logo from '../Assets/sccl.png';
import logo2 from '../Assets/logo2.png';
import sampleImage1 from '../Assets/sample1.jpeg';
import sampleImage2 from '../Assets/sample2.jpeg';
import sampleImage3 from '../Assets//Gallery/1.jpeg';
import sampleImage4 from '../Assets//Gallery/2.jpeg';
import sampleImage5 from '../Assets/sample3.jpeg';
import './../App.css'; // Ensure this is imported for custom CSS

function Homepage() {
  const fixedContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    paddingTop: '32px',
    width: '100%',
    zIndex: 1000, // Ensure it stays on top of other content
    backgroundColor: 'white',
  };

  const headerStyle = {
    width: '100%',
  };

  const contentStyle = {
    paddingTop: '175px', // Adjust to match the height of the fixed header and logo
  };

  const logoContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const logoStyle = {
    width: '125px', // Increase the size as needed
    height: 'auto',
  };
  const logo2Style = {
    width: '100px', // Increase the size as needed
    height: 'auto',
  };

  const textContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '20px',
    marginRight: '20px',
  };

  const textStyle = {
    fontWeight: 'bold',
    fontSize: '28px',
    color: 'black',
  };

  const subtextStyle = {
    fontWeight: 'bold',
    fontSize: '16px',
    color: 'black',
  };

  const carouselContainer = {
    width: '100%', // Occupy full width
  };

  const carouselItemStyle = {
    position: 'relative',
    height: '650px', // Increased height for images
    overflow: 'hidden',
  };

  const captionStyle = {
    position: 'absolute',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'black',
    background: 'black', // Transparent background
    padding: '10px 20px',
    borderRadius: '5px',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'contain', // Ensures images cover the area without distortion
    background:'black'
  };

  return (
    <div>
      <div style={fixedContainerStyle}>
        <div style={logoContainerStyle}>
          <img src={logo} alt="SCCL Logo" style={logoStyle} />
          <div style={textContainerStyle}>
            <div style={textStyle}>Singareni Collieries High School Sector-III</div>
            <div style={subtextStyle}>8 Incline, Godavarikhani</div>
          </div>
          <img src={logo2} alt="Secondary Logo" style={logo2Style} />
        </div>
        <div style={headerStyle}>
          <Header />
        </div>
      </div>
      <div style={contentStyle}>
        <div style={carouselContainer}>
          <Carousel>
            <Carousel.Item style={carouselItemStyle}>
              <img className="d-block w-100" src={sampleImage1} alt="Sample 1" style={imageStyle} />
              <Carousel.Caption style={captionStyle}>
                <h3>Bus Facility</h3>
                <p>Enabling students to reach school safely</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item style={carouselItemStyle}>
              <img className="d-block w-100" src={sampleImage2} alt="Sample 2" style={imageStyle} />
              <Carousel.Caption style={captionStyle}>
                <h3>World Environment Day</h3>
                <p>Participation in world environment day</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item style={carouselItemStyle}>
              <img className="d-block w-100" src={sampleImage4} alt="Sample 3" style={imageStyle} />
              <Carousel.Caption style={captionStyle}>
                <h3>Message from our CMD</h3>
                <p>CBSE is now in SCHS SECTOR 3</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item style={carouselItemStyle}>
              <img className="d-block w-100" src={sampleImage3} alt="Sample 4" style={imageStyle} />
              <Carousel.Caption style={captionStyle}>
                <h3>Our Students</h3>
                <p>Reaching milestones</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item style={carouselItemStyle}>
              <img className="d-block w-100" src={sampleImage5} alt="Sample 5" style={imageStyle} />
              <Carousel.Caption style={captionStyle}>
                <h3>NCC</h3>
                <p>Students actively participating in NCC</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        <AboutUs />
        <Gallery />
        {/* <Faculty /> */}
        <ContactUs />
      </div>
    </div>
  );
}

export default Homepage;
