import React, { useState } from 'react';
import { Modal, Button, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import imagePaths from '../Assets/Gallery/ImagePaths';

const Gallery = () => {
    const [show, setShow] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setCurrentIndex(index);
        setShow(true);
    };

    const galleryStyle = {
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
        marginTop:'16px'
    };

    const titleStyle = {
        fontWeight: 'bold',
        fontSize: '28px',
        marginBottom: '20px',
    };

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '10px',
        marginTop:'16px'
    };

    const imageStyle = {
        width: '100%',
        height: '150px', // Fixed height for gallery images
        objectFit: 'cover',
        objectPosition: 'top', // Crop from the top
        cursor: 'pointer', // Add cursor pointer to indicate that the image is clickable
    };

    const underlineStyle = {
        content: '',
        display: 'block',
        width: '50px',
        height: '4px',
        backgroundColor: 'darkorange',
        margin: '10px auto',
      };

    return (
        <div id="gallery" style={galleryStyle}>
            <div style={titleStyle}>Gallery</div>
            <div style={underlineStyle}></div>
            <div style={gridStyle}>
                {imagePaths.map((image, index) => (
                    <div key={index} onClick={() => handleShow(index)}>
                        <img
                            src={image}
                            alt={`Sample ${index + 1}`}
                            style={imageStyle}
                            onError={(e) => {
                                console.log(`Error loading image: ${image}`);
                                e.target.src = ''; // Set a default or placeholder image if the image fails to load
                            }}
                        />
                    </div>
                ))}
            </div>

            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Gallery</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel activeIndex={currentIndex} onSelect={(selectedIndex) => setCurrentIndex(selectedIndex)}>
                        {imagePaths.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img className="d-block w-100" src={image} alt={`Sample ${index + 1}`} />

                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Gallery;
