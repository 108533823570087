import React from 'react';

const AboutUs = () => {
  const aboutUsStyle = {
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    marginTop:'16px'
  };

  const titleStyle = {
    fontWeight: 'bold',
    fontSize: '28px',
    marginBottom: '20px',
  };

  const underlineStyle = {
    content: '',
    display: 'block',
    width: '50px',
    height: '4px',
    backgroundColor: 'darkorange',
    margin: '10px auto',
  };

  const missionStyle = {
    fontSize: '18px',
    lineHeight: '1.6',
  };

  return (
    <div id="about" style={aboutUsStyle}>
      <div style={titleStyle}>About Us</div>
      <div style={underlineStyle}></div>
      <div style={missionStyle}>
        Our mission is to provide high-quality education and create a nurturing environment where students can thrive. We are dedicated to fostering academic excellence, character development, and lifelong learning.
      </div>
    </div>
  );
}

export default AboutUs;
