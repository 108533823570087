const imagePaths = [
    require('./1.jpeg'),
    require('./2.jpeg'),
    require('./3.jpeg'),
    require('./4.jpeg'),
    require('./5.jpeg'),
    require('./6.jpeg'),
    require('./7.jpeg'),
    require('./8.jpeg'),
    require('./9.jpeg'),
    require('./10.jpeg'),
    require('./11.jpeg'),
    require('./12.jpeg'),
    require('./13.jpeg'),
    require('./14.jpeg'),
    require('./15.jpeg'),
    require('./16.jpeg'),
    require('./17.jpeg'),
  ];
  
  export default imagePaths;
  