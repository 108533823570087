import React, { useEffect } from 'react';

function Header() {
  const loadGoogleFont = () => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  useEffect(() => {
    loadGoogleFont();
  }, []);

  const smoothScrollTo = (target, duration) => {
    const start = window.pageYOffset;
    const end = target.getBoundingClientRect().top + start - 100; // Adjust this value if necessary
    const startTime = performance.now();

    const scroll = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeInOutQuad = progress < 0.5 ? 2 * progress * progress : -1 + (4 - 2 * progress) * progress;
      window.scrollTo(0, start + (end - start) * easeInOutQuad);
      if (elapsed < duration) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      smoothScrollTo(element, 1000); // Smooth scroll with a 1 second duration
    } else {
      console.error(`Element with id "${id}" not found.`);
    }
  };

  const handleMouseEnter = (event) => {
    event.target.style.boxShadow = '0 1px 0 0 gray';
    event.target.style.transform = 'translateY(-3px)';
  };

  const handleMouseLeave = (event) => {
    event.target.style.boxShadow = 'none';
    event.target.style.transform = 'translateY(0)';
  };

  const container = {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
    width: '100%',
    backgroundColor: '#FF5733',
  };

  const getTextStyle = (section) => ({
    color: 'white',
    marginLeft: '25px',
    marginRight: '25px',
    verticalAlign: 'middle',
    display: 'inline-block',
    cursor: 'pointer',
    padding: '2px',
    borderRadius: '3px',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    // fontSize: '20px', // Increased font size
    //fontWeight: 'bold', // Added bold font weight
  });

  return (
    <div style={container}>
      <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={getTextStyle('home')}
        onClick={() => scrollToSection('home')}
      >
        Home
      </span>
      {/* <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={getTextStyle('faculty')}
        onClick={() => scrollToSection('faculty')}
      >
        Faculty
      </span> */}
      <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={getTextStyle('gallery')}
        onClick={() => scrollToSection('gallery')}
      >
        Gallery
      </span>
      <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={getTextStyle('about')}
        onClick={() => scrollToSection('about')}
      >
        About Us
      </span>
      <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={getTextStyle('contact')}
        onClick={() => scrollToSection('contact')}
      >
        Contact Us
      </span>
      <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={getTextStyle('contact')}>
        Web mail
      </span>
    </div>
  );
}

export default Header;
